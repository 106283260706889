import {
  Howl,
  Howler
} from 'howler';

class Audio {

  constructor(options) {

    const defaults = {
      src: '',
      player: '',
      button: '.play-pause-button',
      volume: '.volume-slider',
      mute: '.sound-on-off',
      time: '.time-slider',
      duration: '.duration'
    };

    this.params = Object.assign({}, defaults, options || {});
    this.player = document.querySelector(this.params.player);
    this.button = this.player.querySelector(this.params.button);
    this.volumeSlider = this.player.querySelector(this.params.volume);
    this.muteButton = this.player.querySelector(this.params.mute);
    this.timeSlider = this.player.querySelector(this.params.time);
    this.duration = this.player.querySelector(this.params.duration);
    this.soundId = null;
    this.muted = false;

    this.sound = new Howl({
      src: [this.params.src],
      html5: true,
      onplay: () => {
        requestAnimationFrame(this.updateTimer.bind(this));
      },
      onseek: () => {
        requestAnimationFrame(this.updateTimer.bind(this));
      }
    });

    this.addEvents();


  }

  addEvents() {

    // Play/Pause
    if (this.button) {
      this.button.addEventListener('click', (event) => {
        event.preventDefault();
        this.togglePlay();
      });
    }


    // Volume
    if (this.volumeSlider) {
      this.volumeSlider.addEventListener('input', (event) => {
        event.preventDefault();
        this.volume();
      });
    }

    // Mute
    if (this.muteButton) {
      this.muteButton.addEventListener('click', (event) => {
        event.preventDefault();
        this.toggleMute();
      });
    }


    // Time
    if (this.timeSlider) {
      this.timeSlider.addEventListener('input', (event) => {
        event.preventDefault();
        this.seek();
      });
    }


  }

  togglePlay() {
    const playing = this.soundId && this.sound.playing(this.soundId);
    if (playing) {
      this.sound.pause(this.soundId);
      this.button.classList.add('paused');
      this.button.classList.remove('playing');
    } else {
      this.soundId = this.sound.play();
      this.button.classList.add('playing');
      this.button.classList.remove('paused');
    }
  }

  volume() {
    this.sound.volume(this.volumeSlider.value, this.soundId);
  }


  toggleMute() {
    if (this.muted) {
      this.muted = false;
      this.sound.mute(false, this.soundId);
      this.muteButton.classList.add('sound-on');
      this.muteButton.classList.remove('sound-off');
    } else {
      this.muted = true;
      this.sound.mute(true, this.soundId);
      this.muteButton.classList.add('sound-off');
      this.muteButton.classList.remove('sound-on');
    }
  }

  seek() {
    const modifier = this.timeSlider.value ? this.timeSlider.value / 100 : 0;
    const position = this.sound.duration() * modifier;
    this.sound.seek(position, this.soundId);
  }

  updateTimer() {
    const current = this.sound.seek();
    const duration = this.sound.duration();
    const percentage = (current / duration) * 100;
    this.timeSlider.value = percentage;
    this.duration.innerHTML = this.secondsToDuration(current) + '/' + this.secondsToDuration(duration);
    if (this.sound.playing()) {
      requestAnimationFrame(this.updateTimer.bind(this));
    }
  }

  secondsToDuration(value) {

    value = parseInt(value, 10);

    let hours = Math.floor(value / 3600);
    let minutes = Math.floor((value - (hours * 3600)) / 60);
    let seconds = value - (hours * 3600) - (minutes * 60);

    if (hours && hours < 10) {
      hours = '0' + hours;
    }

    if (minutes && minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    let result = '';

    if (parseInt(hours, 10) > 0) {
      result += hours + ':';
    }

    result += minutes + ':' + seconds;

    return result;
  }

}

export {
  Audio as
  default
};
