import axios from 'axios';

class Newsletter {
  constructor(options) {

    var defaults = {
      messages: {
        success: 'Completed',
        failure: 'Failed'
      }
    };
    this.params = Object.assign({}, defaults, options || {});
    this.container = document.querySelector(this.params.selector);
    this.form = this.container.querySelector('form');
    this.endpoint = this.form.getAttribute('action');
    this.log = this.container.querySelector('.newsletter-module-response');

    this.addEvents();
  }

  addEvents() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      this.log.innerHTML = '';
      this.subscribe();
    });
  }

  subscribe() {
    var data = this.getFormData();
    axios.post(this.endpoint, data)
      .then((response) => {
        if (response.data.success) {
          this.log.innerText = this.params.messages.success;
        } else {
          this.log.innerText = response.data.message ? response.data.message : this.params.messages.failure;
        }
      })
      .catch((error) => {
        this.log.innerText = this.params.messages.failure;
      });
  }

  getFormData() {
    var data = new FormData();
    this.form.querySelectorAll('input').forEach((element) => {
      data.append(element.name, element.value);
    });
    return data;
  }


}

export {
  Newsletter as
    default
};
