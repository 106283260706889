import axios from 'axios';
import {
  debounce
} from 'debounce';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

class Carousel {
  constructor(options) {

    var defaults = {
      itemsToScroll: 3,
      paginationUrl: null,
      paginationLimit: 25,
      paginationEnd: 3,
      mode: 'horizontal'
    };

    this.params = Object.assign({}, defaults, options || {});
    this.container = document.querySelector(this.params.selector);
    if (!this.container) {
      return;
    }
    this.carousel = this.container.querySelector('.carousel');
    this.carouselItemsContainer = this.container.querySelector('.carousel-items');
    this.carouselItems = this.container.querySelectorAll('.carousel-item');
    this.navigationButtons = this.container.querySelectorAll('.carousel-navigation-button');
    this.previousButton = this.container.querySelector('.carousel-previous');
    this.previousButton.disabled = true;
    this.nextButton = this.container.querySelector('.carousel-next');
    this.progressBar = this.container.querySelector('.carousel-progress-bar');
    this.activeIndicator = this.container.querySelector('.carousel-active-index');
    this.active = 0;
    if (this.progressBar) {
      this.updateProgressBar();
    }
    this.totalIndicator = this.container.querySelector('.carousel-total');
    if (this.totalIndicator) {
      let total = this.carouselItems.length;
      if (total < 10) {
        total = '0' + (this.carouselItems.length);
      }
      this.totalIndicator.textContent = total;
    }

    this.paginating = false;
    this.limit = this.params.paginationLimit;
    this.maxPages = this.params.paginationEnd;
    this.start = 0;
    this.page = 1;

    this.addEvents();
  }

  addEvents() {
    for (var index = 0; index < this.navigationButtons.length; index++) {
      this.addNavigationButtonEvent(index);
    }
    if (this.previousButton) {
      this.previousButton.addEventListener('click', (event) => this.navigate(event, 'previous'));
    }
    if (this.nextButton) {
      this.nextButton.addEventListener('click', (event) => this.navigate(event, 'next'));
    }
    if (this.previousButton || this.nextButton) {
      this.carouselItemsContainer.addEventListener('scroll', debounce((event) => this.onScroll(event), 50));
    }

    this.carouselItemsContainer.addEventListener('refresh', (e) => {
      this.refresh();
      this.carouselItemsContainer.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, false);

  }

  addNavigationButtonEvent(index) {
    this.navigationButtons[index].addEventListener('click', (event) => {
      event.preventDefault();
      this.navigateTo(index);
    });
  }

  onScroll(event) {

    this.previousButton.disabled = this.params.mode === 'horizontal' ? this.carouselItemsContainer.scrollLeft <= 0 : this.carouselItemsContainer.scrollTop <= 0;

    if (this.previousButton.disabled) {
      this.previousButton.classList.add('disabled');
    } else {
      this.previousButton.classList.remove('disabled');
    }


    var endOffset = this.params.mode === 'horizontal' ? this.carouselItemsContainer.scrollWidth - this.carouselItemsContainer.clientWidth : this.carouselItemsContainer.scrollHeight - this.carouselItemsContainer.clientHeight;
    this.nextButton.disabled = this.params.mode === 'horizontal' ? this.carouselItemsContainer.scrollLeft >= endOffset : this.carouselItemsContainer.scrollTop >= endOffset;
    if (this.nextButton.disabled) {
      this.nextButton.classList.add('disabled');
    } else {
      this.nextButton.classList.remove('disabled');
    }

    this.active = this.params.mode === 'horizontal' ? Math.round(this.carouselItemsContainer.scrollLeft / this.getFirstItemWidth()) : Math.round(this.carouselItemsContainer.scrollTop / this.getFirstItemHeight());
    for (let i = 0; i < this.navigationButtons.length; i++) {
      this.navigationButtons[i].classList.remove('active');
    }

    if (this.navigationButtons && this.navigationButtons.length) {
      this.navigationButtons[this.active].classList.add('active');
    }


    if (this.progressBar) {
      this.updateProgressBar();
    }

    if (this.activeIndicator) {
      let indicator = this.active + 1;
      if (indicator < 10) {
        indicator = '0' + (this.active + 1);
      }
      this.activeIndicator.textContent = indicator;
    }

    var paginationOffset = endOffset - 800;
    var paginationCondition = this.params.mode === 'horizontal' ? this.carouselItemsContainer.scrollLeft >= paginationOffset : this.carouselItemsContainer.scrollTop >= paginationOffset;
    if (this.params.paginationUrl && !this.paginating && this.page <= this.maxPages && paginationCondition) {
      this.nextButton.disabled = false;
      this.paginate();
    }
  }

  paginate() {
    this.paginating = true;
    this.carousel.classList.add('paginating');
    this.start += this.limit;
    this.page++;
    axios.get(this.params.paginationUrl + '&start=' + this.start).then((response) => {
      var div = document.createElement('div');
      div.innerHTML = response.data;
      div.classList.add('carousel-items-group');
      this.carouselItemsContainer.appendChild(div);
      this.refresh();
      this.carousel.classList.remove('paginating');
      this.paginating = false;
    });
  }


  refresh() {
    this.carouselItems = this.container.querySelectorAll('.carousel-item');
    this.active = 0;
  }

  navigate(event, direction) {

    event.preventDefault();

    if (direction === 'previous') {
      this.active--;
      if (this.active < 0) {
        this.active = 0;
      }
    } else {
      this.active++;
      if (this.active >= this.carouselItems.length) {
        this.active = this.carouselItems.length - 1;
      }
    }

    this.navigateTo(this.active);
  }

  navigateTo(index) {
    this.active = index;
    if (this.params.mode === 'horizontal') {
      var left = index * this.getFirstItemWidth();
      this.carouselItemsContainer.scroll({
        top: 0,
        left: left,
        behavior: 'smooth'
      });
    } else {
      var top = index * this.getFirstItemHeight();
      this.carouselItemsContainer.scroll({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    }

  }


  getFirstItemWidth() {
    var style = window.getComputedStyle(this.carouselItems[0]);
    var width = parseInt(style.width) + parseInt(style.marginLeft) + parseInt(style.marginRight);
    return width;
  }

  getFirstItemHeight() {
    var style = window.getComputedStyle(this.carouselItems[0]);
    var height = parseInt(style.height) + parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }

  updateProgressBar() {
    if (this.params.mode === 'horizontal') {
      this.progressBar.style.width = (((this.active + 1) / this.carouselItems.length) * 100) + '%';
    } else {
      this.progressBar.style.height = (((this.active + 1) / this.carouselItems.length) * 100) + '%';
    }
  }
}

export {
  Carousel as
  default
};
