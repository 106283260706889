import Carousel from './carousel';

class Gallery {
  constructor(options) {
    this.carousel = new Carousel(options);
    this.options = options;
    var defaults = {};
    this.params = Object.assign({}, defaults, options || {});
    this.modal = document.querySelector('#modal');
    this.modalContent = this.modal.querySelector('.modal-stage');
    this.modalState = 0;
    this.container = document.querySelector(options.selector);
    this.openModalButtons = this.container.querySelectorAll('.open-modal');
    this.closeModalButton = this.modal.querySelector('.close-modal');
    this.photos = this.container.querySelectorAll('.gallery-item');
    this.addEvents();
  }

  addEvents() {
    for (var index = 0; index < this.openModalButtons.length; index++) {
      this.openModalButtons[index].addEventListener('click', (event) => {
        event.preventDefault();
        this.show();
        this.modalCarousel.navigateTo(parseInt(event.target.dataset.index));
      });
    }
    this.closeModalButton.addEventListener('click', (event) => {
      event.preventDefault();
      this.hide();
    });
    window.addEventListener('keydown', (event) => this.onKeyDown(event));

  }

  show() {
    this.initModalCarousel(this.options);
    this.modal.classList.add('visible-modal');
    document.body.classList.add('with-open-modal');
    this.modalState = 1;
  }

  hide() {
    this.modal.classList.remove('visible-modal');
    document.body.classList.remove('with-open-modal');
    this.modalContent.querySelector('.carousel-items').remove();
    this.modalState = 0;
  }

  initModalCarousel(options) {
    const append = this.photos[0].parentNode.cloneNode(true);
    const images = append.querySelectorAll('.gallery-item img');
    for (var index = 0; index < images.length; index++) {
      images[index].src = options.images[index].img_main;
    }
    this.modalContent.appendChild(append);
    options.mode = 'vertical';
    options.selector = '#modal .modal-stage';
    this.modalCarousel = new Carousel(options);
    if (this.modalCarousel.activeIndicator) {
      this.modalCarousel.activeIndicator.textContent = '01';
    }
    for (var index = 0; index < this.modalCarousel.carouselItems.length; index++) {
      this.modalCarousel.carouselItems[index].addEventListener('click', (event) => {
        this.modalCarousel.carouselItemsContainer.classList.toggle('hide-description');
      });
    }
  }

  onKeyDown(event) {
    if (!this.modalState) {
      return;
    }
    if (event.keyCode == 27) {
      this.hide();
    }
  }


}

export {
  Gallery as
    default
};
