class Header {
	constructor(options) {
  
	  var defaults = {
		breakpoint: 72
	  };
  
	  this.params = Object.assign({}, defaults, options || {});
  
	  this.body = document.body;
	  this.setupStickyHeader();
	  this.menuTogglers();
	  this.searchTogglers();
	}
	
	menuTogglers() {
		var menuTogglers = document.querySelectorAll('.rich-menu');
		menuTogglers.forEach((toggler) => {
			toggler.addEventListener('click', function(event) {
			event.preventDefault();
			this.classList.toggle('is-open');
			document.body.classList.toggle('menu-opened');
			});
		});
	}

	searchTogglers() {
		var searchTogglers = document.querySelectorAll('.search-toggler');
		searchTogglers.forEach((toggler) => {
			toggler.addEventListener('click', function(event) {
			event.preventDefault();
			this.classList.toggle('is-open');
			document.body.classList.toggle('search-opened');
			});
		});
	}
	
  
	setupStickyHeader() {
	  window.addEventListener('scroll', (e) => {
		if (window.scrollY >= this.params.breakpoint) {
		  this.body.classList.add('collapse-header');
		} else {
		  this.body.classList.remove('collapse-header');
		}
	  });
	}
  }
  
  export {
	Header as
	default
  };
